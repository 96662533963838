/**
 * @author Johan Magnusson (johan.magnusson@svenskaspel.se)
 */
const TUR = {
  DUR_OPEN: 0.25,
  DUR_CLOSE: 0.1,
  DELAY: 0,
  EASING: 'power2.out'
};

const NEUTRAL = TUR;

const SPORT = {
  DUR_OPEN: 0.15,
  DUR_CLOSE: 0.1,
  DELAY: 0,
  EASING: 'power1.out'
};

export default {
  TUR,
  SPORT,
  NEUTRAL
};
