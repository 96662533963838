/** * @author Team Nummer */import animations from './animations';
const SPACEBAR_KEY_CODE = 32;
const ENTER_KEY = 13;
svs.ui.ExpandableCard = class ExpandableCard {
  constructor(options) {
    this.el = options.el;
    this.setUpListeners();
  }

  setUpListeners() {
    let el;
    if (this.el instanceof Element) {
      el = this.el;
    } else {
      el = document.querySelector(this.el);
    }
    if (el) {
      const keydownCallback = (e) => this.handleKeyDown(e);
      if (el.classList.contains('js-card-header-expandable')) {
        el.addEventListener('click', this.toggleExpandableBody);
        el.addEventListener('keydown', keydownCallback);
      } else {
        const elements = el.querySelectorAll('.js-card-header-expandable');
        elements.forEach((elem) => {
          elem.addEventListener('click', this.toggleExpandableBody);
          elem.addEventListener('keydown', keydownCallback);
        });
      }
    }
  }

  handleKeyDown(e) {
    switch (e.keyCode) {
      case SPACEBAR_KEY_CODE:
      case ENTER_KEY:
        e.preventDefault();
        this.toggleExpandableBody(e);
        break;
      default:
    }
  }

  toggleExpandableBody({ currentTarget: { parentNode: el } }) {
    const expandableBody = el.querySelector('.js-card-expandable-body');
    if (expandableBody) {
      const containsExpandableBodyClosed = expandableBody.classList.contains('card-expandable-body-closed');
      if (containsExpandableBodyClosed) {
        expandableBody.style.height = 0;
      }
      const { branding, expandablelist, isinitalexpanded } = expandableBody.dataset;
      animations.toggleCardAnimate(branding, containsExpandableBodyClosed, expandablelist === 'true', el, isinitalexpanded === 'true');
    }
  }
};
