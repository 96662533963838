/**
 * @author Eyjolfur Gudjonsson (eyjolfor.gudjonsson@svenskaspel.se)
 */
import gsap from 'gsap';
import constants from './constants';

/**
 * Returns animation settings based on customer.
 * @param customer
 * @returns {{EASING: string, DUR_OPEN: number, DUR_CLOSE: number, DELAY: number}}
 */

const getAnimationSettings = (customer) => constants[`${customer.toUpperCase()}`] || constants.NEUTRAL;

const toggleCardAnimate = (customer, expanded, expandableList, el, iconFlipped) => {
  if (el) {
    const animSettings = getAnimationSettings(customer);
    if (expanded) {
      Object.keys(el?.children).forEach((key) => {
        if (el.children[key].classList.contains('card-expandable-body')) {
          el.children[key].classList.remove('card-expandable-body-closed');
        }
      });
    }

    gsap.to(el, {
      margin: expanded && expandableList ? '24px 0 24px 0' : '0 0 0 0',
      duration: animSettings.DUR_OPEN,
      ease: animSettings.EASING,
      delay: 0
    });

    gsap.to(el.querySelector('.card-expandable-body'), {
      opacity: expanded ? 1 : 0,
      height: expanded ? 'auto' : 0,
      duration: expanded ? animSettings.DUR_OPEN : animSettings.DUR_CLOSE,
      delay: animSettings.DELAY,
      ease: animSettings.EASING,
      onComplete: () => {
        if (!expanded) {
          Object.keys(el?.children).forEach((key) => {
            if (el.children[key].classList.contains('card-expandable-body')) {
              el.children[key].classList.add('card-expandable-body-closed');
            }
          });
        }
      }
    });
    const iconExpandedRotation = iconFlipped ? 0 : 180;
    const iconNotExpandedRotation = iconFlipped ? 180 : 0;
    gsap.to(el.querySelector('.js-card-icon-animate'), {
      rotate: expanded ? iconExpandedRotation : iconNotExpandedRotation,
      duration: animSettings.DUR_OPEN,
      delay: animSettings.DELAY,
      ease: animSettings.EASING
    });
  }
};

export default { toggleCardAnimate };
